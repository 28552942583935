@media only screen and (max-width: 767px) {
  .section-title {
    margin-bottom: 30px;
  }
  .section-title span {
    font-size: 15px;
  }
  .section-title h3 {
    font-size: 26px;
    margin: 5px 0 0 0;
  }

  .default-btn-one {
    margin-right: 0;
  }

  .ptb-100 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .pb-100 {
    padding-bottom: 50px;
  }

  .pb-70 {
    padding-bottom: 30px;
  }

  .pt-100 {
    padding-top: 50px;
  }

  .default-btn-one {
    padding-left: 30px;
    padding-right: 30px;
  }

  .default-btn {
    padding-left: 30px;
    padding-right: 30px;
  }

  .main-banner-area {
    height: 100%;
    padding-top: 130px;
    padding-bottom: 280px;
  }
  .main-banner-area .banner-image {
    margin-top: 25px;
  }
  .main-banner-area .banner-image img:nth-child(1) {
    display: none;
  }
  .main-banner-area .banner-image img:nth-child(2) {
    display: none;
  }
  .main-banner-area .banner-image img:nth-child(3) {
    display: none;
  }
  .main-banner-area .banner-image img:nth-child(4) {
    display: none;
  }
  .main-banner-area .banner-image img:last-child {
    display: block;
  }
  .main-banner-area .creative-shape {
    bottom: -3px;
  }

  .main-banner-content {
    text-align: center;
  }
  .main-banner-content h1 {
    font-size: 26px;
    margin: 0 0 12px 0;
  }
  .main-banner-content p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .main-banner-content .banner-btn {
    margin-top: 20px;
  }
  .main-banner-content .banner-btn .default-btn {
    margin-left: 5px;
  }

  .main-banner-two {
    height: 100%;
    padding-top: 130px;
    padding-bottom: 400px;
  }
  .main-banner-two .banner-image {
    margin-top: 25px;
  }
  .main-banner-two .banner-image img:nth-child(1) {
    display: none;
  }
  .main-banner-two .banner-image img:nth-child(2) {
    display: none;
  }
  .main-banner-two .banner-image img:nth-child(3) {
    display: none;
  }
  .main-banner-two .banner-image img:nth-child(4) {
    display: none;
  }
  .main-banner-two .banner-image img:nth-child(5) {
    display: none;
  }
  .main-banner-two .banner-image img:last-child {
    display: block;
  }
  .main-banner-two .circle-img {
    display: none;
  }
  .main-banner-two .creative-shape {
    bottom: -3px;
  }

  .main-banner-three {
    height: 100%;
    padding-top: 130px;
    padding-bottom: 240px;
  }
  .main-banner-three .banner-image {
    margin-top: 25px;
  }
  .main-banner-three .banner-image img:nth-child(1) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(2) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(3) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(4) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(5) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(6) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(7) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(8) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(9) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(10) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(11) {
    display: none;
  }
  .main-banner-three .banner-image img:last-child {
    display: block;
  }
  .main-banner-three .creative-shape {
    bottom: -3px;
  }

  .main-banner-four {
    height: 100%;
    padding-top: 130px;
    padding-bottom: 100px;
  }
  .main-banner-four .main-banner-content {
    text-align: center;
  }
  .main-banner-four .main-banner-content h1 {
    font-size: 34px;
    margin: 0 0 12px 0;
  }
  .main-banner-four .main-banner-content .banner-btn {
    margin-top: 20px;
  }
  .main-banner-four .main-banner-content .banner-btn .video-btn {
    font-size: 14px;
  }
  .main-banner-four .main-banner-content .banner-btn .video-btn i {
    margin-left: 8px;
  }
  .main-banner-four .banner-image {
    margin-top: 25px;
  }
  .main-banner-four .creative-shape {
    bottom: 0;
    left: 0;
  }

  .about-image {
    margin-bottom: 30px;
  }

  .about-content h3 {
    font-size: 26px;
    margin: 5px 0 8px 0;
  }
  .about-content span {
    font-size: 15px;
  }
  .about-content .about-btn {
    margin-top: 20px;
  }

  .data-service-section {
    padding-bottom: 30px !important;
  }

  .data-services-item .single-data-service-box h3 {
    font-size: 17px;
  }

  .data-service-image {
    margin-bottom: 30px;
  }

  .fun-facts-area {
    padding-bottom: 20px !important;
  }

  .single-fun-fact {
    margin-bottom: 30px;
  }
  .single-fun-fact h3 {
    font-size: 32px;
    margin-top: -20px;
  }
  .single-fun-fact p {
    font-weight: 500;
    font-size: 16px;
  }

  .work-item .work-content {
    max-width: 100%;
    margin-top: -6px;
  }

  .solutions-list-tab .tabs {
    margin-bottom: 30px;
  }
  .solutions-list-tab .tabs li {
    -ms-flex: unset;
    -webkit-box-flex: unset;
    flex: unset;
    max-width: unset;
    padding-top: 10px;
    margin-right: 10px;
  }
  .solutions-list-tab .tabs li a {
    padding-right: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    font-size: 16px;
  }
  .solutions-list-tab .tab_content .tabs_item .tab-solution-content h3 {
    font-size: 25px;
    margin: 0 0 8px 0;
  }
  .solutions-list-tab .tab_content .tabs_item .tab-solution-content .tab-list {
    margin-bottom: 20px;
    margin-top: 16px;
    position: relative;
    padding-left: 32px;
  }
  .solutions-list-tab .tab_content .tabs_item .tab-solution-content .tab-list i {
    position: absolute;
    left: 0;
  }
  .solutions-list-tab .tab-image {
    margin-top: 35px;
  }

  .testimonial-slider .testimonial-single-item .testimonial-content .icon {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .testimonial-slider.owl-theme .owl-nav [class*=owl-] {
    left: 0;
  }
  .testimonial-slider.owl-theme .owl-nav [class*=owl-].owl-next {
    right: 0;
  }

  .blog-image img {
    width: 100%;
  }

  .blog-item .single-blog-item {
    padding: 20px 16px;
  }
  .blog-item .single-blog-item .blog-content h3 {
    font-size: 20px;
  }
  .blog-item .single-blog-item .blog-list li {
    font-size: 12px;
    margin-right: 8px;
  }
  .blog-item .single-blog-item .blog-list i::before {
    font-size: 12px;
  }

  .partner-title {
    margin-bottom: 30px;
  }
  .partner-title span {
    font-size: 15px;
  }
  .partner-title h3 {
    font-size: 26px;
    margin: 5px 0 0 0;
  }

  .partner-list .partner-item {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .partner-list .partner-item:nth-child(6) {
    margin-left: 0;
  }

  .subscribe-content {
    margin-bottom: 25px;
  }
  .subscribe-content h2 {
    margin-bottom: 12px;
  }
  .subscribe-content .sub-title {
    font-size: 14px;
    font-weight: 500;
  }

  .newsletter-form button {
    position: relative;
    margin-top: 25px;
  }

  .single-services-box {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 25px;
    padding-left: 25px;
  }
  .single-services-box h3 {
    font-size: 18px;
    top: 42px;
    left: 80px;
  }

  .software-section {
    padding-bottom: 50px !important;
  }

  .software-content span {
    font-size: 15px;
  }
  .software-content h2 {
    font-size: 26px;
    margin: 5px 0 8px 0;
  }
  .software-content .features-list li {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .software-image {
    margin-top: 30px;
  }

  .productive-content span {
    font-size: 15px;
  }
  .productive-content h3 {
    font-size: 26px;
    margin: 5px 0 8px 0;
  }
  .productive-content .productive-btn .productive-btn-one {
    margin-left: 0;
  }

  .productive-image {
    margin-top: 30px;
  }

  .project-section {
    padding-bottom: 30px !important;
  }

  .subscribe-section {
    padding-bottom: 50px;
  }

  .subscribe-content-area {
    padding: 30px;
    margin-bottom: 0;
  }
  .subscribe-content-area .subscribe-content h2 {
    font-size: 26px;
    margin-top: 25px;
  }
  .subscribe-content-area .newsletter-form .input-newsletter {
    height: 58px;
  }
  .subscribe-content-area .newsletter-form button {
    position: relative;
    right: 0;
    top: 0;
    height: 50px;
    margin-top: 20px;
  }
  .subscribe-content-area .newsletter-form #validator-newsletter {
    bottom: -20px;
    font-size: 12px;
  }

  .single-project .image-hover {
    left: 20px;
  }

  .contact-image {
    margin-bottom: 35px;
  }

  .page-title-area {
    height: 300px;
  }
  .page-title-area .page-title-bg-about, .page-title-area .page-title-bg-contact, .page-title-area .page-title-bg-service, .page-title-area .page-title-bg-careers {
    height: 300px;
  }

  .page-title-content h2 {
    font-size: 30px;
  }
  .page-title-content ul {
    margin-top: 6px;
  }

  .services-details-overview {
    margin-bottom: 0;
  }

  .services-details-image {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .projects-details-desc .project-details-info {
    margin-top: 16px;
  }
  .projects-details-desc .project-details-info .single-info-box {
    -ms-flex: unset;
    -webkit-box-flex: unset;
    flex: unset;
    max-width: unset;
    padding-bottom: 10px;
  }

  .single-pricing-box .pricing-header h3 {
    margin-bottom: 4px;
    font-size: 22px;
  }
  .single-pricing-box .price {
    font-size: 40px;
    margin-bottom: 5px;
    margin-top: 8px;
  }

  .woocommerce-result-count {
    margin-bottom: 20px;
  }

  .pagination-area {
    margin-top: 0;
  }
  .pagination-area .page-numbers {
    width: 40px;
    height: 40px;
    margin: 0px 1px;
    line-height: 40px;
    font-size: 15px;
  }

  .product-details-desc h3 {
    margin-top: 20px;
  }
  .product-details-desc .custom-payment-options .payment-methods a img {
    width: 36px;
  }

  .products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information li span {
    width: 50%;
  }
  .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .default-btn {
    display: none;
  }
  .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item {
    position: relative;
    padding-right: 0;
  }
  .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .review-report-link {
    position: relative;
    top: 6px;
  }

  .related-products {
    margin-top: 50px;
  }

  .cart-table table thead tr th {
    padding: 0 35px 15px;
    font-size: 14px;
  }

  .text-end {
    text-align: center !important;
    margin-top: 10px;
  }

  .cart-totals {
    padding: 25px;
    margin-top: 32px;
  }

  .order-details .title {
    margin-top: 25px;
  }
  .order-details .payment-box {
    margin-top: 25px;
    padding: 20px;
  }

  .faq-accordion .accordion .accordion-item .accordion-title {
    padding: 20px;
    font-size: 18px;
  }

  .coming-soon-area {
    height: 100%;
    padding-top: 160px;
    padding-bottom: 150px;
  }

  .coming-soon-content h1 {
    margin-bottom: 10px;
    font-size: 30px;
  }
  .coming-soon-content #timer div {
    margin-bottom: 30px;
  }

  .widget-area .widget_plamb_posts_thumb .item .info .title {
    font-size: 16px;
  }
  .widget-area .widget_search {
    box-shadow: unset;
    padding: 0;
  }
  .widget-area .widget_search form.search-top {
    margin-top: 40px;
  }
  .widget-area .widget_search form.search-bottom {
    margin-top: 0;
  }

  .comments-area {
    padding-bottom: 0;
  }

  .tagcloud.section-top {
    margin-bottom: 30px;
  }
  .tagcloud.section-bottom {
    margin-bottom: 0;
  }

  .blog-details-desc .article-content h3 {
    font-size: 20px;
  }
  .blog-details-desc .article-footer .article-share .social li a {
    width: 25px;
    height: 25px;
    line-height: 25px;
  }

  blockquote, .blockquote {
    padding: 18px !important;
  }
  blockquote p, .blockquote p {
    font-size: 16px !important;
  }

  .single-footer-widget .footer-heading {
    margin-bottom: 12px;
  }
  .single-footer-widget .footer-heading h2 {
    font-size: 22px;
  }
  .single-footer-widget .footer-social {
    margin-bottom: 20px;
  }
  .single-footer-widget .footer-quick-links li:last-child {
    padding-bottom: 25px;
  }

  .copyright-area {
    text-align: center;
  }
  .copyright-area ul {
    text-align: center;
  }

  .navbar-brand {
    width: 70%;
  }

  .main-banner-two .creative-shape h3 {
    font-size: 18px;
    padding: 0 20px 0 20px;
    color: transparent;
  }

  .banner-bottom-tgtext {
    margin-top: 0 !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .work-item .work-content {
    max-width: 100%;
    margin-top: -6px;
  }

  .single-footer-widget .footer-quick-links li:last-child {
    padding-bottom: 0;
  }

  .project-image img {
    width: 100%;
  }

  .text-end {
    margin-top: 0;
  }

  .main-banner-area {
    height: 100%;
    padding-top: 130px;
    padding-bottom: 450px;
  }

  .main-banner-two {
    height: 100%;
    padding-top: 130px;
    padding-bottom: 580px;
  }

  .main-banner-three {
    height: 100%;
    padding-top: 130px;
    padding-bottom: 400px;
  }

  .main-banner-two .creative-shape h3 {
    font-size: 18px;
    color: transparent;
  }

  .banner-bottom-tgtext {
    margin-top: 0 !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title {
    margin-bottom: 30px;
  }
  .section-title span {
    font-size: 15px;
  }
  .section-title h3 {
    font-size: 26px;
    margin: 5px 0 0 0;
  }

  .ptb-100 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .pb-100 {
    padding-bottom: 50px;
  }

  .pb-70 {
    padding-bottom: 30px;
  }

  .pt-100 {
    padding-top: 50px;
  }

  .navbar-area .navbar .navbar-nav {
    margin: 0 0 0 auto;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu {
    left: auto;
    right: 0;
  }
  .navbar-area .navbar .other-option {
    display: none;
  }

  .main-banner-area {
    height: 100%;
    padding-top: 130px;
    padding-bottom: 630px;
  }
  .main-banner-area .banner-image {
    margin-top: 25px;
  }
  .main-banner-area .banner-image img:nth-child(1) {
    display: none;
  }
  .main-banner-area .banner-image img:nth-child(2) {
    display: none;
  }
  .main-banner-area .banner-image img:nth-child(3) {
    display: none;
  }
  .main-banner-area .banner-image img:nth-child(4) {
    display: none;
  }
  .main-banner-area .banner-image img:last-child {
    display: block;
  }
  .main-banner-area .creative-shape {
    bottom: -8px;
  }

  .main-banner-content {
    margin: auto;
    text-align: center;
  }
  .main-banner-content h1 {
    font-size: 34px;
    margin: 0 0 12px 0;
  }
  .main-banner-content .banner-btn {
    margin-top: 20px;
  }
  .main-banner-content p {
    margin-bottom: 20px;
  }

  .main-banner-two {
    height: 100%;
    padding-top: 130px;
    padding-bottom: 780px;
  }
  .main-banner-two .banner-image {
    margin-top: 25px;
  }
  .main-banner-two .banner-image img:nth-child(1) {
    display: none;
  }
  .main-banner-two .banner-image img:nth-child(2) {
    display: none;
  }
  .main-banner-two .banner-image img:nth-child(3) {
    display: none;
  }
  .main-banner-two .banner-image img:nth-child(4) {
    display: none;
  }
  .main-banner-two .banner-image img:nth-child(5) {
    display: none;
  }
  .main-banner-two .banner-image img:last-child {
    display: block;
  }
  .main-banner-two .circle-img img {
    display: none;
  }
  .main-banner-two .creative-shape {
    bottom: -3px;
  }

  .main-banner-three {
    height: 100%;
    padding-top: 130px;
    padding-bottom: 580px;
  }
  .main-banner-three .banner-image {
    margin-top: 25px;
  }
  .main-banner-three .banner-image img:nth-child(1) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(2) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(3) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(4) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(5) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(6) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(7) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(8) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(9) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(10) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(11) {
    display: none;
  }
  .main-banner-three .banner-image img:last-child {
    display: block;
  }
  .main-banner-three .creative-shape {
    bottom: -3px;
  }

  .main-banner-four {
    height: 100%;
    padding-top: 130px;
    padding-bottom: 100px;
  }
  .main-banner-four .main-banner-content {
    text-align: center;
  }
  .main-banner-four .main-banner-content h1 {
    font-size: 34px;
    margin: 0 0 12px 0;
  }
  .main-banner-four .main-banner-content .banner-btn {
    margin-top: 20px;
  }
  .main-banner-four .main-banner-content .banner-btn .video-btn {
    font-size: 14px;
  }
  .main-banner-four .main-banner-content .banner-btn .video-btn i {
    margin-left: 8px;
  }
  .main-banner-four .banner-image {
    margin-top: 25px;
  }
  .main-banner-four .creative-shape {
    bottom: 0;
    left: 0;
  }

  .about-image {
    margin-bottom: 30px;
    text-align: center;
  }

  .about-content h3 {
    font-size: 26px;
    margin: 5px 0 8px 0;
  }
  .about-content span {
    font-size: 15px;
  }
  .about-content .about-btn {
    margin-top: 20px;
  }

  .data-service-section {
    padding-bottom: 30px !important;
  }

  .data-service-image {
    margin-bottom: 30px;
    text-align: center;
  }

  .fun-facts-area {
    padding-bottom: 20px !important;
  }

  .single-fun-fact {
    margin-bottom: 30px;
  }
  .single-fun-fact h3 {
    font-size: 32px;
    line-height: 0;
    margin-bottom: 0;
  }
  .single-fun-fact p {
    font-weight: 500;
    font-size: 16px;
  }

  .work-item .work-content {
    max-width: 100%;
    margin-top: -6px;
  }

  .solutions-list-tab .tabs {
    margin-bottom: 30px;
  }
  .solutions-list-tab .tabs li {
    -ms-flex: unset;
    -webkit-box-flex: unset;
    flex: unset;
    max-width: unset;
    padding-top: 10px;
    margin-right: 10px;
  }
  .solutions-list-tab .tabs li a {
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    font-size: 18px;
  }
  .solutions-list-tab .tab_content .tabs_item .tab-solution-content h3 {
    font-size: 25px;
    margin: 0 0 8px 0;
  }
  .solutions-list-tab .tab_content .tabs_item .tab-solution-content .tab-list {
    margin-bottom: 20px;
    margin-top: 16px;
    position: relative;
    padding-left: 32px;
  }
  .solutions-list-tab .tab_content .tabs_item .tab-solution-content .tab-list i {
    position: absolute;
    left: 0;
  }
  .solutions-list-tab .tab-image {
    margin-top: 20px;
    text-align: center;
  }

  .testimonial-slider .testimonial-single-item .testimonial-content .icon {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .testimonial-slider.owl-theme .owl-nav [class*=owl-] {
    left: 0;
  }
  .testimonial-slider.owl-theme .owl-nav [class*=owl-].owl-next {
    right: 0;
  }

  .blog-image img {
    width: 100%;
  }

  .blog-item .single-blog-item {
    padding: 20px 16px;
  }
  .blog-item .single-blog-item .blog-content h3 {
    font-size: 20px;
  }
  .blog-item .single-blog-item .blog-list li {
    font-size: 12px;
    margin-right: 8px;
  }
  .blog-item .single-blog-item .blog-list i::before {
    font-size: 12px;
  }

  .partner-title {
    margin-bottom: 30px;
  }
  .partner-title span {
    font-size: 15px;
  }
  .partner-title h3 {
    font-size: 26px;
    margin: 5px 0 0 0;
  }

  .partner-list .partner-item {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 33%;
    max-width: 33%;
  }
  .partner-list .partner-item:nth-child(6) {
    margin-left: 0;
  }

  .subscribe-content {
    margin-bottom: 25px;
  }
  .subscribe-content h2 {
    margin-bottom: 12px;
  }
  .subscribe-content .sub-title {
    font-size: 14px;
    font-weight: 500;
  }

  .newsletter-form button {
    padding: 0 18px;
    font-size: 14px;
  }

  .single-services-box {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 25px;
    padding-left: 25px;
  }
  .single-services-box h3 {
    font-size: 18px;
    top: 42px;
    left: 80px;
  }

  .software-section {
    padding-bottom: 50px !important;
  }

  .software-content span {
    font-size: 15px;
  }
  .software-content h2 {
    font-size: 26px;
    margin: 5px 0 8px 0;
  }
  .software-content .features-list li {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .software-image {
    margin-top: 30px;
    text-align: center;
  }

  .productive-content span {
    font-size: 15px;
  }
  .productive-content h3 {
    font-size: 26px;
    margin: 5px 0 8px 0;
  }

  .productive-image {
    margin-top: 30px;
    text-align: center;
  }

  .project-section {
    padding-bottom: 30px !important;
  }

  .subscribe-section {
    padding-bottom: 50px;
  }

  .subscribe-content-area {
    padding: 30px;
    margin-bottom: 0;
  }
  .subscribe-content-area .subscribe-content h2 {
    font-size: 26px;
    margin-top: 25px;
  }
  .subscribe-content-area .newsletter-form .input-newsletter {
    height: 58px;
  }
  .subscribe-content-area .newsletter-form button {
    position: relative;
    right: 0;
    top: 0;
    height: 50px;
    margin-top: 20px;
  }
  .subscribe-content-area .newsletter-form #validator-newsletter {
    bottom: -20px;
    font-size: 12px;
  }

  .contact-image {
    margin-bottom: 35px;
    text-align: center;
  }

  .page-title-area {
    height: 300px;
  }
  .page-title-area .page-title-bg-about, .page-title-area .page-title-bg-contact, .page-title-area .page-title-bg-service, .page-title-area .page-title-bg-careers {
    height: 300px;
  }

  .page-title-content h2 {
    font-size: 30px;
  }
  .page-title-content ul {
    margin-top: 6px;
  }

  .services-details-overview {
    margin-bottom: 0;
  }

  .services-details-image {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .projects-details-desc .project-details-info {
    margin-top: 20px;
  }
  .projects-details-desc .project-details-info .single-info-box {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
    padding-bottom: 10px;
  }

  .product-details-desc h3 {
    margin-top: 20px;
  }

  .related-products {
    margin-top: 50px;
  }

  .order-details .title {
    margin-top: 25px;
  }

  .widget-area .widget_plamb_posts_thumb .item .info .title {
    font-size: 16px;
  }
  .widget-area .widget_search {
    box-shadow: unset;
    padding: 0;
  }
  .widget-area .widget_search form.search-top {
    margin-top: 40px;
  }
  .widget-area .widget_search form.search-bottom {
    margin-top: 0;
  }

  .comments-area {
    padding-bottom: 0;
  }

  .tagcloud.section-top {
    margin-bottom: 30px;
  }
  .tagcloud.section-bottom {
    margin-bottom: 0;
  }

  .single-footer-widget .footer-heading {
    margin-bottom: 12px;
  }
  .single-footer-widget .footer-heading h2 {
    font-size: 22px;
  }
  .single-footer-widget .footer-social {
    margin-bottom: 20px;
  }
  .single-footer-widget .footer-quick-links li:last-child {
    padding-bottom: 25px;
  }

  .copyright-area {
    text-align: left;
  }
  .copyright-area ul {
    text-align: right;
  }

  .main-banner-two .creative-shape h3 {
    font-size: 20px;
    color: transparent;
  }

  .banner-bottom-tgtext {
    margin-top: 0 !important;
    color: #fff;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-banner-area {
    height: 800px;
  }
  .main-banner-area .banner-image {
    margin-top: -200px;
  }
  .main-banner-area .banner-image img:nth-child(1) {
    display: none;
  }
  .main-banner-area .banner-image img:nth-child(2) {
    display: none;
  }
  .main-banner-area .banner-image img:nth-child(3) {
    display: none;
  }
  .main-banner-area .banner-image img:nth-child(4) {
    display: none;
  }
  .main-banner-area .banner-image img:last-child {
    display: block;
  }
  .main-banner-area .creative-shape {
    bottom: -12px;
  }

  .main-banner-two {
    height: 800px;
  }
  .main-banner-two .banner-image {
    margin-top: -200px;
  }
  .main-banner-two .banner-image img:nth-child(1) {
    display: none;
  }
  .main-banner-two .banner-image img:nth-child(2) {
    display: none;
  }
  .main-banner-two .banner-image img:nth-child(3) {
    display: none;
  }
  .main-banner-two .banner-image img:nth-child(4) {
    display: none;
  }
  .main-banner-two .banner-image img:nth-child(5) {
    display: none;
  }
  .main-banner-two .banner-image img:last-child {
    display: block;
  }
  .main-banner-two .circle-img img {
    display: none;
  }

  .main-banner-three {
    height: 800px;
  }
  .main-banner-three .banner-image {
    margin-top: -100px;
  }
  .main-banner-three .banner-image img:nth-child(1) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(2) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(3) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(4) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(5) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(6) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(7) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(8) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(9) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(10) {
    display: none;
  }
  .main-banner-three .banner-image img:nth-child(11) {
    display: none;
  }
  .main-banner-three .banner-image img:last-child {
    display: block;
  }

  .main-banner-four {
    height: 800px;
  }
  .main-banner-four .banner-image {
    margin-top: 0;
  }
  .main-banner-four .creative-shape {
    bottom: 0;
    left: 0;
  }

  .testimonial-slider.owl-theme .owl-nav [class*=owl-] {
    left: 0;
  }
  .testimonial-slider.owl-theme .owl-nav [class*=owl-].owl-next {
    right: 0;
  }

  .blog-item .single-blog-item {
    padding: 20px 16px;
  }
  .blog-item .single-blog-item .blog-content h3 {
    font-size: 20px;
  }
  .blog-item .single-blog-item .blog-list li {
    font-size: 12px;
    margin-right: 8px;
  }
  .blog-item .single-blog-item .blog-list i::before {
    font-size: 12px;
  }

  .single-services-box {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 25px;
    padding-left: 25px;
  }
  .single-services-box h3 {
    font-size: 18px;
    top: 42px;
    left: 80px;
  }

  .software-content .features-list li span {
    padding: 15px;
    font-size: 15px;
  }

  .data-service-section {
    padding-bottom: 70px !important;
  }

  .data-services-item .single-data-service-box h3 {
    font-size: 17px;
  }

  .single-project .image-hover {
    left: 18px;
  }

  .single-footer-widget .footer-heading h3 {
    font-size: 19px;
  }

  .main-banner-two .creative-shape h3 {
    font-size: 20px;
    color: transparent;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1400px) {
  .circle-img {
    position: absolute;
    top: -322px !important;
    left: 0px !important;
    width: 670px !important;
  }

  .main-banner-two .banner-image img:nth-child(1) {
    top: -322px;
    left: 0%;
    width: 730px;
  }

  .main-banner-content h1 {
    font-size: 40px;
  }
  .main-banner-content p {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1800px) {
  .circle-img {
    position: absolute;
    top: -348px !important;
    left: 34px !important;
    width: 680px !important;
  }

  .main-banner-content h1 {
    font-size: 40px;
  }

  .main-banner-two .banner-image img:nth-child(1) {
    top: -310px;
    left: 6%;
    width: 640px;
  }

  .engraved {
    font-size: 100px;
    font-family: Futura;
    background-color: #673aa3;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: rgba(183, 213, 214, 0.5) 3px 3px 1px;
  }

  .embossed {
    color: #f0f0f0;
    font-size: 100px;
    font-family: Futura;
    background-color: #666666;
    text-shadow: 1px 1px 4px #664890;
    text-align: center;
    -webkit-background-clip: text;
    -moz-background-clip: text;
  }

  .main-banner-two {
    height: 100vh;
  }
  .main-banner-two .creative-shape {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -2;
  }
  .main-banner-two .creative-shape h3 {
    font-size: 24px;
  }
}
@media only screen and (min-width: 1800px) {
  .main-banner-area {
    overflow: hidden;
    position: relative;
    z-index: 1;
    height: 950px;
    background: linear-gradient(to bottom, #7724b2, #7724b2, #370b6f, #370b6f);
  }
  .main-banner-area .banner-image {
    margin-top: 0;
    -webkit-animation: movebounce 5s linear infinite;
    animation: movebounce 5s linear infinite;
    position: relative;
  }
  .main-banner-area .banner-image img {
    position: absolute;
  }
  .main-banner-area .banner-image img:nth-child(1) {
    top: -220px;
    left: 45%;
  }
  .main-banner-area .banner-image img:nth-child(2) {
    left: 10%;
    top: -100px;
  }
  .main-banner-area .banner-image img:nth-child(3) {
    left: 5%;
    z-index: 1;
    top: -210px;
  }
  .main-banner-area .banner-image img:nth-child(4) {
    left: 46%;
    top: 184px;
    z-index: 2;
  }
  .main-banner-area .banner-image img:last-child {
    display: none;
  }
  .main-banner-area .creative-shape {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -1;
  }

  .main-banner-two {
    overflow: hidden;
    position: relative;
    z-index: 1;
    height: 100vh;
    background: linear-gradient(to bottom, #21172d, #4a257a, #6f20a7, #803ebe);
  }
  .main-banner-two .banner-image {
    margin-top: 150px;
    position: relative;
  }
  .main-banner-two .banner-image img {
    position: absolute;
  }
  .main-banner-two .banner-image img:nth-child(1) {
    top: -310px;
    left: 6%;
    width: 640px;
  }
  .main-banner-two .banner-image img:nth-child(2) {
    right: 22%;
    top: -211px;
  }
  .main-banner-two .banner-image img:nth-child(3) {
    left: 20%;
    z-index: 999;
    top: -37px;
  }
  .main-banner-two .banner-image img:nth-child(4) {
    right: 22%;
    top: -38px;
    z-index: 999;
  }
  .main-banner-two .banner-image img:nth-child(5) {
    left: 30%;
    top: -112px;
    z-index: 1;
  }
  .main-banner-two .banner-image img:last-child {
    display: none;
  }
  .main-banner-two .circle-img {
    position: absolute;
    top: -362px;
    left: 34px;
    width: 680px;
  }
  .main-banner-two .circle-img img {
    animation-name: rotateMe;
    animation-duration: 35s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .main-banner-two .creative-shape {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -2;
  }
  .main-banner-two .shape-dot {
    position: absolute;
    z-index: -1;
    -webkit-animation: movebounce 5s linear infinite;
    animation: movebounce 5s linear infinite;
  }
  @keyframes rotateMe {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .main-banner-three {
    overflow: hidden;
    position: relative;
    z-index: 1;
    height: 950px;
    background: linear-gradient(to bottom, #4b43c5, #385bd5, #2270e2, #0c84ec, #1397f3);
  }
  .main-banner-three .banner-image {
    position: relative;
  }
  .main-banner-three .banner-image img {
    position: absolute;
  }
  .main-banner-three .banner-image img:nth-child(1) {
    top: -200px;
    left: 40%;
  }
  .main-banner-three .banner-image img:nth-child(2) {
    left: 25%;
    top: -110px;
  }
  .main-banner-three .banner-image img:nth-child(3) {
    right: 25%;
    z-index: 1;
    top: -150px;
  }
  .main-banner-three .banner-image img:nth-child(4) {
    right: 5%;
    top: -6px;
    z-index: 2;
  }
  .main-banner-three .banner-image img:nth-child(5) {
    right: 2%;
    top: -220px;
    z-index: 2;
  }
  .main-banner-three .banner-image img:nth-child(6) {
    right: 20%;
    top: -320px;
  }
  .main-banner-three .banner-image img:nth-child(7) {
    left: 18%;
    top: -320px;
    z-index: 2;
  }
  .main-banner-three .banner-image img:nth-child(8) {
    left: 5px;
    top: -165px;
    z-index: 1;
  }
  .main-banner-three .banner-image img:nth-child(9) {
    left: 0;
    top: 0;
  }
  .main-banner-three .banner-image img:nth-child(10) {
    left: 28%;
    top: 65px;
    z-index: 1;
    -webkit-animation: movebounce 5s linear infinite;
    animation: movebounce 5s linear infinite;
  }
  .main-banner-three .banner-image img:nth-child(11) {
    right: 26%;
    top: 70px;
    -webkit-animation: movebounce 5s linear infinite;
    animation: movebounce 5s linear infinite;
  }
  .main-banner-three .banner-image img:last-child {
    display: none;
  }
  .main-banner-three .creative-shape {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -1;
  }
}